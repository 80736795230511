<template>
  <!-- 历史数据 -->
  <v-container fluid>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="3">
            <v-select :label="$t('lang.station.stationName')" :items="roverStationList" return-object item-value="sid" item-text="name" v-model="selectedRoverStation"></v-select>
          </v-col>
          <v-col cols="12" md="3">
            <v-datetime-picker :label="$t('lang.history.startTime')" v-model="startTime" time-format="HH:mm:ss" :time-picker-props="timeProps"> 
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-time-five-outline</v-icon>
              </template>
          </v-datetime-picker>
          </v-col>
          <v-col cols="12" md="3">
            <v-datetime-picker :label="$t('lang.history.endTime')" v-model="endTime" time-format="HH:mm:ss" :time-picker-props="timeProps"> 
              <template v-slot:dateIcon>
                <v-icon>mdi-calendar-outline</v-icon>
              </template>
              <template v-slot:timeIcon>
                <v-icon>mdi-clock-time-five-outline</v-icon>
              </template>
          </v-datetime-picker>
          </v-col>
          <v-col cols="12" md="3" class="mt-2">
            <v-btn color="primary" @click="getHistoryData()">{{$t('lang.setting.submitBtn')}}</v-btn>
            <v-btn color="primary" class="ml-2" @click="exportData()">{{$t('lang.history.exportBtn')}}</v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div
              id="e_chart"
              :class="$vuetify.breakpoint.smAndDown ? 'min-chart-size' : 'max-chart-size'"
            ></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
             <div
              id="n_chart"
              :class="$vuetify.breakpoint.smAndDown ? 'min-chart-size' : 'max-chart-size'"
            ></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="d-flex justify-center">
            <div
              id="u_chart"
              :class="$vuetify.breakpoint.smAndDown ? 'min-chart-size' : 'max-chart-size'"
            ></div>
          </v-col>
        </v-row>    
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import Plotly from "plotly.js-dist-min";
import axios from "axios";
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl;
// plotly配置参数
let plotConfig = {
  displaylogo: false,
  responsive: true,
};
let time = [], e = [], n = [], u = []
export default {
    data() {
        return {
          roverStationList: [],
          selectedRoverStation: '',
          startTime: '',
          endTime: '',
          timeProps: {
            useSeconds: true,
            ampmInTitle: true
          },
          historyData: []
        }
    },
    created() {
      this.getStationList()
    },
    mounted() {
      this.initLineChart()
    },
    methods: {
      getStationList() {
        axios.get(`${apiUrl}station/list`).then(res => {
          if(res.status == 200) {
            this.roverStationList = res.data.filter(station => station.type == 'ROVER')  
            //console.log(this.roverStationList)
          }
        }).catch(error => {
          console.log(error)
        })
      },
      // 计算数据差值
      calDiffValue (data) {
        let diff = []
        if (data.length > 1) {
          for (let i = 1; i < data.length; i++) {
            diff.push(data[i] - data[i - 1])
          }
        }
        return diff
      },
      getHistoryData() {
        if(!this.selectedRoverStation || !this.startTime || !this.endTime) return
        if(this.endTime <= this.startTime) {
          console.log('time error')
          return
        }
        let param = {
          sid: this.selectedRoverStation.sid,
          start: Math.round(Date.parse(this.startTime)/1000),
          end: Math.round(Date.parse(this.endTime)/1000)
        }
        axios.get(`${apiUrl}data/gnss/history?sid=${param.sid}&start=${param.start}&end=${param.end}`).then(res => {
          console.log(res)
          if(res.status != 200) return
          this.historyData = res.data
          time = []; e = []; n = [];u = []
          for(let data of res.data){
            time.push(new Date(data.time))
            e.push(data.e)
            n.push(data.n)
            u.push(data.u)
          }
          e = this.calDiffValue(e)
          n = this.calDiffValue(n)
          u = this.calDiffValue(u)
          this.initLineChart()
        }).catch(error => {
          console.log(error)
        })
      },
      // 瀑布图数据配置
      waterfallData (xData, yData) {
        let data = {
            //name: name,
            type: "waterfall",
            orientation: "v",
            x: xData,
            textposition: "outside",
            y: yData,
            connector: {
              line: {
                color: "rgb(63, 63, 63)"
              }
            },
            increasing: { marker: { color: "blue" } },
            decreasing: { marker: { color: "orange" } }
          }
        return [data]
      },
      // 瀑布图布局配置
      waterfallLayout (title) {
        let layout = {
          title: {
            text: title
          },
          yaxis: {
            type: "linear"
          },
          autosize: true,
          showlegend: false
        };
        return layout
      },
      initLineChart() {
        Plotly.newPlot("e_chart",this.waterfallData(time, e), this.waterfallLayout('E'), plotConfig);
        Plotly.newPlot("n_chart",this.waterfallData(time, n), this.waterfallLayout('N'), plotConfig);
        Plotly.newPlot("u_chart",this.waterfallData(time, u), this.waterfallLayout('U'), plotConfig);
      },
      exportData() {
        if (this.historyData.length <= 0) {
          return;
        }
        // Building the CSV from the Data two-dimensional array
        // Each column is separated by ";" and new line "\n" for next row
        var csvContent = "sid,time,e,n,u,x,y,z\n";
        this.historyData.forEach(function (history) {
          let infoArray = [history.sid, timestampToTime(history.time), history.e, history.n, history.u, history.x, history.y, history.z]
          let dataString = infoArray.join(",");
          csvContent += dataString + "\n";
        });

        // The download function takes a CSV string, the filename and mimeType as parameters
        // Scroll/look down at the bottom of this snippet to see how download is called
        var download = function (content, fileName, mimeType) {
          var a = document.createElement("a");
          mimeType = mimeType || "application/octet-stream";

          if (navigator.msSaveBlob) {
            // IE10
            navigator.msSaveBlob(
              new Blob([content], {
                type: mimeType
              }),
              fileName
            );
          } else if (URL && "download" in a) {
            //html5 A[download]
            a.href = URL.createObjectURL(
              new Blob([content], {
                type: mimeType
              })
            );
            a.setAttribute("download", fileName);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          } else {
            location.href =
              "data:application/octet-stream," + encodeURIComponent(content); // only this mime type is supported
          }
        };
        let name = this.selectedRoverStation.name + ".csv";
        download(csvContent, name, "text/csv;encoding:utf-8");
      }
    }
}
function timestampToTime (timestamp) {
  var date = new Date(timestamp);
  var Y = date.getFullYear() + "-";
  var M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  var D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
  var h =
    (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  var m =
    (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
    ":";
  var s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
</script>
<style scoped>
.min-chart-size {
  max-width: 300px;
  max-height: 300px;
}
.max-chart-size {
  max-width: 600px;
  max-height: 500px;
}
</style>